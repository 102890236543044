
.chats {
    margin: 10px;
    padding: 10px;

    border-radius: 15px;
    background-color: rgba( 200,200,200, .3 );
    float: top;
    position: relative;
    height: 600px;
}


.chat_messages {
    max-height: 500px;
    min-height: 500px;
    float: top;
    position: relative;
    overflow-y: scroll;
}

.chat_message_user {
    text-align: center;
    font-size: 20px;
}

.chat_message_timestamp {
    text-align: center;
    color: #1b6d85;
}

.chat_message_chat {
    color: white;
    white-space: pre-line;
}

.chat_input {
    margin: 25px;
    width: 100%;
    float: bottom;
    position: absolute;
}

.chat_message_input {
    width: 100%;
}

.chat_message_send {
    width: 100%;
}

.chats a {
    color: rgba( 255,055,055, .8 );
}