.content {

    position: absolute;
    padding-left: 30px;
    margin-left: 0px;
    padding-right: 50px;
    transition: padding-right 1.5s, padding-left 1.5s;
    width: 100%;
}

.content > h1 {


    font-family: Monoton;
    font-family: UnifrakturCook;
    font-size: 42px;

    text-shadow: 4px 4px 2px gray;
    width: 100%;
    color: rgba(255,2555,255, .8);
}

.hasSidebar {

    padding-left: 300px;
}

.iframe-theme {
    width: 100%;
    height: 800px;
    background-color: rgba(200,200,200,.5);
    border-color: black;
    border-radius: 10px;
    border-width: 2px;
}

.iframe-theme body {
    color: white !important;
}

.onlineUsers {
    width: 100%;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    color: gold;

}

.onlineUsers div {
    vertical-align: middle;
    display:inline-block;
}

.onlineUsers div .onlineUser {
    margin-left: 5px;
}