.bookmarks {
    width: 100%;
}

.bookmarkItem {
    width: 200px;
    height: 100px;
    background-color: rgba(200,200,200,.3);
    position: relative;
    -webkit-animation-duration: 15s; /* Safari 4.0 - 8.0 */
    animation-duration: 15s;
    float: left;
    margin: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.bookmarkItem a img{
    width: 200px;
    height: 100px;
    border-radius: 15px;
}

.bookmarkItem p{
    background-color: rgba(200,200,200,.1);
    border-radius: 15px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

/* Standard syntax */
@keyframes item_1 {
    0%   {left:0px; top:0px;}
    100% {left:1000px; top:800px;}
}