.dashlet {
    width: 650px;
    height: 400px;

    margin-left: 10px;
    float: left;
    margin-right: 10px;

    margin-top: 20px;
}
.dashletItem {

    color: black;
    width: 650px;
    height: 400px;
    border: 2px;
    border-style: solid;
    border-radius: 15px;
    border-color: black;

    padding: 20px 20px 20px 20px;

    box-shadow: 10px 10px 5px rgba(0,0,0, .3);

    background-color: rgba(255,255,255, .7);

    transition: width 1.5s;
}

.dashlet-double-size {
    width: 1320px !important;
}

.dashlet-item .button-flipper-config {
    position: relative;
    float: right;
    padding-top: 5px;
    padding-right: 10px;
    cursor: pointer;
    font-size: 150%;
    text-shadow: 5px 5px 3px gray;
}

.dashletItem {

    width: 100%;
    postion: absolute;
    color: black;
}

.dashletItem h1 {
    padding-bottom: 15px;
    text-shadow: 3px 3px 2px gray;
}

.form-control {
    background-color: rgba(255,255,255, .6) !important;
}


.dashlet-control-elements {
    text-align: center;
    horiz-align: center;
}

.noDataDashlet {
    margin-top: 70px;
    width: 100%;
    text-align: center;
    font-size: xx-large;
    color: red;
    font-weight: bold;
}
.dashlet-icon {
    font-size: 500%;
}


.backface-visibility-hidden {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.flipper-container {
    z-index: 1;
    float: left;
    width: 100% !important;
    display: block;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    -ms-perspective: 1000px;
}
.flipper-container span {
    color: white;
}
.flipper-container > div.flipper {
    z-index: 1;
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
}
.flipper-container > div.flipper .front,
.flipper-container > div.flipper .back {
    float: left;
    display: block;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -webkit-transition: -webkit-transform ease 2500ms !important;
    -moz-transition: -webkit-transform ease 2500ms !important;
    -ms-transition: -webkit-transform ease 2500ms !important;
    -o-transition: -webkit-transform ease 2500ms !important;
    transition: -webkit-transform ease 2500ms !important;
    -webkit-transition: transform ease 2500ms !important;
    -moz-transition: transform ease 2500ms !important;
    -ms-transition: transform ease 2500ms !important;
    -o-transition: transform ease 2500ms !important;
    transition: transform ease 2500ms !important;
}
.flipper-container .tile {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.flipper-container.horizontal .front {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
}
.flipper-container.horizontal .back {
    transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
}
.flipper-container.horizontal div.flipper.flipped .front {
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
}
.flipper-container.horizontal div.flipper.flipped .back {
    transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
}
.flipper-container.vertical .front {
    transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
}
.flipper-container.vertical .back {
    transform: rotateX(-180deg);
    -ms-transform: rotateX(-180deg);
    -webkit-transform: rotateX(-180deg);
    -o-transform: rotateX(-180deg);
    -moz-transform: rotateX(-180deg);
}
.flipper-container.vertical div.flipper.flipped .front {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
}
.flipper-container.vertical div.flipper.flipped .back {
    transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
}
