
.calendar {
    background-color: rgba(180,180,180, .3);
    border-radius: 15px;
    padding: 15px;
}

.cal-month {
    padding: 0;
    margin-bottom: 20px;
}

.month {
    background-color: rgba(180,180,180, .3);
    border-radius: 15px;
    padding-bottom: 10px;
}

.cal-header {
    text-align: center;
    font-size: 40px;

    color: rgba(255,255,255, .8);
    font-family: Monoton;
}

.cal-navigation {
    text-align: center;
    width: auto;
    border-radius: 40px;
    margin-bottom: 20px;
    cursor: pointer;
}

.cal-navigation:hover {
    background-color: rgba(255,255,100, 0.4);
}