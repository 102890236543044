
.backups {
    background-color: rgba( 100,100,100, .5);
    padding: 15px;
    color: white;
    border-radius: 15px;
}

.backups .head {
    font-weight: bold;
    font-size: 15px;
    padding: 5px;
    background-color: rgba( 100, 255, 100, .5);
}

.backups .data {
    margin-top: 2px;
}

.cpuload .data:hover {
    background-color: rgba(255,255,255, 0.4);
}