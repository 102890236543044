.login_frame {
    width: 100%;
    margin-top: 10%;
}

.login_dialog {
    width: 500px;
    background-color: rgba( 100,100,100, .6 );
    margin: auto;
    padding: 30px;

    font-family: 'UnifrakturCook';
    font-size: x-large;
    color: white;
}

.login_submit {
    margin: 0;
    padding: 0;
    margin-top: 25px;
    width: 100%;

    background-color: #CCC;
}