.statistic-key {

    font-weight: bold;
}

.statistic-value {

    text-align: right;
}

.row-even {
    background-color: rgba(0,0,0, .2);
}

.statistic-table {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
}