

.candidatur {
    margin-top: 30px;
    background-color: rgba(0,0,0, 0.4);
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

    border-radius: 15px;

}

.candidatur-header {
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: rgba(150,0,150, 0.4);
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.candidatur-content {
    font-size: 12px;
    color: white;
    margin-top: 10px;
    padding: 5px;
 }

.candidatur-content:hover {
    background-color: rgba(255,255,255, 0.4);
}

.job_firma {
    font-weight: bold;
    font-size: 15px;
}
.job {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 15px;
}
.firmen_name {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 15px;
}

.job_description {
    margin-bottom: 15px;
}

.candidatur_ref {
    margin-bottom: 15px;
}

.ownComment {
    margin-top: 15px;
}

.create_new {
    background-color: orange !important;
    margin-right: 20px !important;
}

.selected {
    background-color: #006600 !important;
}

.candidatur-navigation div {
    margin-right: 10px;
}

.candidatur_rejected {
    color: lightblue;
}

.btn-calendar {
    background-color: lightgoldenrodyellow !important;
}

.candidatur_list_answer > div {
    padding-left: 10px;
}

.pulse {
    cursor: pointer;
    animation: pulse 4s infinite;
}
.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        background-color: rgba(255, 174, 66, 0.1);
    }
    50% {
        background-color: rgba(255, 174, 66, 0.3);
    }
    100% {
        background-color: rgba(255, 174, 66, 0.1);
    }
}
@keyframes pulse {
    0% {
        background-color: rgba(255, 174, 66, 0.1);
    }
    50% {
        background-color: rgba(255, 174, 66, 0.3);
    }
    100% {
        background-color: rgba(255, 174, 66, 0.1);
    }
}

.statusBar {
    width: 100%;
    background-color: yellow;
    text-align: center;
    margin-top: 10px;
}