
.modalDialogCadidatur {

    position: absolute;
    left: 0px;
    border-radius: 15px;
}

.header {

    font-family: Monoton;
    font-family: UnifrakturCook;
    font-size: 42px;
    margin-bottom: 30px;
}

.descriptionCandidatur {
    margin-bottom: 10px;
    width: 800px;
}

.dialogaction {

    margin-top: 20px;
    float: right;
}

.btn {
    margin-left: 10px;
}

#ownCommentBox {
    width: 100%;
}


.job_description div {
    max-height: 300px;
}
.job_description .value {

    overflow-y: auto;
    white-space: pre-line;
}

.enableDate {
    width: 110px !important;
    margin-right: 20px;
}

.firmen_logo {
    position: fixed;
    text-align: right;
    margin-left: 400px;
    height: 150px;
    width: 400px;
}
.firmen_logo > img {
    float: right;
    height: 150px;
}

textarea {
    resize: vertical;
}

.tab-pane {
  opacity: unset !important;
}