
.serverlogs {
    background-color: rgba( 100,100,100, .5);
    padding-top: 15px;
    color: white;
    border-radius: 15px;
    height: 420px;
    width: 100%;
}

.serverlogs .content {

    overflow-y: scroll;
    height: 350px;
    margin: 0px;
    padding: 5px;
    width: 700px;
}

.serverlogs .head {
    margin: 0px;
    padding: 5px;
    font-weight: bold;
    font-size: 15px;
    background-color: rgba( 100, 255, 100, .5);
    width: 700px;
}

.serverlogs .data {
    margin: 0px;
    padding: 0px;
}

.serverlogs .content .data:hover {
    background-color: rgba(255,255,255, 0.4);
}