.finance .lastYear{

    font-family: "Courier New";
    font-size: 16px;
    float: left;
    box-shadow: 10px 10px 5px rgba(0,0,0, .3);
}

.finance .nextYear{

    font-family: "Courier New";
    font-size: 16px;
    float: right;
    box-shadow: 10px 10px 5px rgba(0,0,0, .3);
}

.header-finance {
    text-align: center;
    font-size: 40px;

    color: rgba(255,255,255, .8);
    font-family: Monoton;
}

.dashlet-container {
    padding-top: 50px;
    padding-left: 0;
    margin-left: -10px;
    float: left;
}