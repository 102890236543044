
.pension {
    border-radius: 15px;
    background-color: rgba( 200,200,200, .6);
    padding: 15px;
}

.pension .head {
    font-size: x-large;
    text-decoration: underline;
}

.pensionHead {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 30px !important;
    text-decoration: underline;
    font-family: Monoton;
}

.table div {
    font-size: 10px;
}
.table .header div {
    margin-bottom: 0px !important;
    font-family: Monoton;
}

.table .rowline {
    height: 2px;
    background-color: black;
    width: 100%;
}

.table .tableContent {
    padding: 5px;
}

.hinweis {
    margin-bottom: 15px;
}
.floatRight {
    text-align: right;
}
.floatCenter {
    text-align: center;
}

.colEven {
    width: 100%;
    background-color: rgba( 200, 200, 200, .3);
}
.colNotEven {
    width: 100%;
    background-color: rgba( 200, 200, 200, .6);
}