
.curriculumVitea {
    margin: 10px;
    padding: 20px;

    background-color: rgba(255,255,255, 0.4);
    border-radius: 10px;
}

.cv-topic {

    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255,255,255, 0.4);
}

.curriculumVitea .cv-head {
    font-size: xx-large;
    color: yellow;
    font-family: Monoton;
    font-family: UnifrakturCook;

    text-shadow: 4px 4px 2px gray;
    padding-bottom: 10px;
}
.cv-persona .key {

    color: brown;
    font-size: large;
    font-weight: bold;
}

.curriculumVitea img {
    box-shadow: 10px 10px 5px #888888;
    border-radius: 10px;
}