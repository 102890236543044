.system > div {
    float: left;
    margin: 10px;
}

.harddiskdrives {
    max-width: 700px;
}

.serverlogs {
    max-width: 700px;
}

.cpuload {
    width: 700px;
}

.backups {
    width: 450px;
}