
.modalDialog {

    position: absolute;
    left: 0px;
    border-radius: 15px;
}

.header {

    font-family: Monoton;
    font-family: UnifrakturCook;
    font-size: 42px;
    margin-bottom: 30px;
}

.description {
    margin-bottom: 10px;
}

.dialogaction {

    margin-top: 20px;
    float: right;
}

.btn {
    margin-left: 10px;
}