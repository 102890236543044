.App {
  text-align: center;
  font-family: 'Bookman Old Style';
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.warning-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.App-header {
  height: 250px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.hide {
  visibility: hidden;
}

.font-pacifico {

  font-family: Pacifico;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 26.4px;
}

.logoutButton {
  font-size: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: yellow;
}