
.cpuload {
    background-color: rgba( 100,100,100, .5);
    padding: 15px;
    color: white;
    border-radius: 15px;
}

.cpuload .head {
    font-weight: bold;
    font-size: 15px;
    padding: 5px;
    background-color: rgba( 255, 100, 100, .5);
}

.cpuload .data {
    margin-top: 2px;
}

.cpuload .data:hover {
    background-color: rgba(255,255,255, 0.4);
}

.cpuload .ok {
    color: green;
}
.cpuload .warning {
    color: yellow;
}
.cpuload .critical {
    color: orange;
}
.cpuload .fatal {
    color: red;
}

.CircularProgressbar-path { stroke: red; }
.CircularProgressbar-trail { stroke: gray; }
.CircularProgressbar-text {
    fill: white;
    font-size: 15px;
}