
.usersettingsPage {
    margin: 20px;
    background-color: rgba( 200,200,200, .3);
    padding: 15px;
    border-radius: 10px;
    color: darkblue;
}

.usersettingsPage div {
    padding-top: 5px;
    padding-bottom: 5px;
}
.usersettingsPage .btn {
    width: 100%;
}

.usersettingsPage .lastlogin {
    margin-bottom: 20px;
}