
.cal-weekdays {
    font-weight: bold;
    font-size: 250%;
    font-family: Monoton;
    background-color: rgba(180,180,180, .3);
}

.cal-item{
    padding: 0px !important;
    text-align: right;
    padding-right: 6px !important;
    padding-top: 3px !important;
    padding-bottom: 0px !important;
    height: 25px;
}

.cal-kw {
    color: white;
    background-color: rgba(180,180, 0, .6);
    font-size: medium;
}
.cal-head-week {
    font-weight: bold;
    font-family: UnifrakturCook;
}
.month-name {
    text-align: center;
    font-weight: bold;
    font-size: larger;
    background-color: rgba(200,200,200, .8);
    border-radius: 15px;
    font-family: UnifrakturCook;
}

.today {
    animation-name: todayAnimation;
    animation-duration: 4s;
    animation-iteration-count: infinite;

    border-style: solid;
    border-color: orange;
    border-width: 1px;
    background-color: yellow;
    border-radius: 10px;
}

@-webkit-keyframes todayAnimation {
    0% {
        border-left-width: 1px;
    }
    50% {
        border-left-width: 3px;
    }
    100% {
        border-left-width: 6px;
    }
}
@keyframes todayAnimation {
    0% {
        border-left-width: 1px;
        border-right-width: 1px;
    }
    50% {
        border-left-width: 3px;
    }
    100% {
        border-left-width: 6px;
    }
}


.cal-weekend {
    background-color: rgba(  0,180, 50, .3);
}

.cal-holiday {
    border-radius: 10px;
    background-color: rgba( 0,0,200, 0.3 );
}

.cal-meeting {
    border-radius: 10px;
    background-color: rgba( 250,0,0, 0.6 ) !important;
}
