
.sidebar {

    position: fixed;
    width: 250px;
    height: 100%;
    background-color: rgba(0,0,0, .3);
    color: beige;
    margin-left: 0px;
    margin-right: 0px;
    transition: margin-left 1.5s;
    box-shadow: 5px 5px 2px rgba(0,0,0, .3);
    float: left;

    z-index: 999;
}

.sidebar-close {
    margin-left: -250px;
}

.sidebar img {

    padding-top: 20px;
    width: 100%;
}

.sidebar .item {

    font-family: 'UnifrakturCook';
    font-size: 24px;

    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    cursor: pointer;

    z-index: -1;
}

.sidebar .subitem {

    font-family: 'UnifrakturCook';
    font-size: 24px;

    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 40px;
    width: 100%;
    cursor: pointer;
    float: left;

    z-index: 9999;
}

.subitem div {
    float: left;
    padding: 0px;
    padding-right: 15px;
}

.subitem .glyphicon {
    color: lightgrey;
}

.sidebar .selected {

    background-color: rgba(255,255,255, .45) !important;
}
.item:hover {
    background-color: rgba(255,255,255, .1);
}
.subitem:hover {
    background-color: rgba(255,255,255, .1);
}

.subitem {
    transition: height 4s;
}

.hidden {
    height: 0 !important;
    visibility: hidden !important;
    padding: 0 !important;
    margin: 0 !important;
}

.buildInformation {

    font-size: x-small;
    text-align: right;
    margin-bottom: 10px;
}

.watch p {
    width: 100%;
    align-content: center;
    text-align: center;

}

.watch {
    margin-top: 10px;
    padding-top: 10px;
    margin-bottom: 15px;
    background-color: rgba(150,150,255, .4);
    border-radius: 20px;
    border-width: 2px;
    border-color: black;
    border-style: groove;
}

.watch .time {

    font-size: 250%;
    font-family: Monoton;
}
.watch .date {

    font-size: 200%;
    font-family: UnifrakturCook;
}

.sidebar .usersettings {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    padding: 3px !important;
    text-align: center;
    color: goldenrod;
    font-size: 20px !important;
}

.logoutButton {
    color: darkgreen;
    animation: logoutAnimation 4s infinite;
}

.highlight {
    animation: highlightAnimation 4s infinite;
}

@-webkit-keyframes highlightAnimation {
    0% {
        color: rgba(250, 250, 0, 0.3);
    }
    50% {
        color: rgba(250, 250, 0, 0.6);
    }
    100% {
        color: rgba(250, 250, 0, 0.3);
    }
}

@-webkit-keyframes logoutAnimation {
    0% {
        color: rgba(0, 250, 0, 0.3);
    }
    50% {
        color: rgba(0, 250, 0, 0.6);
    }
    100% {
        color: rgba(0, 250, 0, 0.3);
    }
}
@keyframes logoutAnimation {
    0% {
        color: rgba(0, 250, 0, 0.1);
    }
    50% {
        color: rgba(0, 250, 0, 0.3);
    }
    100% {
        color: rgba(0, 250, 0, 0.1);
    }
}