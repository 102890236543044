.countdown {
    align-content: center;
    width: 100%;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 15px;
    margin-top: 15px;
}

.counter-frame {

    padding: 5px;

    margin: 10px;

    width: 550px;
    background-size: cover;
    border-radius: 30px;
    margin-top: 15px;
    border: 8px solid black;
    border-color: #985f0d;

    background-color: rgba(150,100,0, .3);
    background-position: bottom;

    color: rgba(50,50,255, .8);
    background-repeat: no-repeat;

    float: left;
}
.counter-testo {
    background-image: url("_logo_testo_.png");
}
.counter-novedia {
    background-image: url("_logo_novedia_.jpg");
}
.counter-kcart {
    background-image: url("_logo_kcart_.jpg");
}
.counter-MailReturn {
    background-image: url("_logo_jobs.jpg");
}

.countertext {

    font-weight: bold;
    font-size: 60px;

    font-family: Monoton;
    font-family: UnifrakturCook;

    text-shadow: 4px 4px 2px gray;
    margin-bottom: 30px;
}

.counter {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
}

.countersubtext {

    font-family: Monoton;
    font-family: UnifrakturCook;
    font-weight: bold;
    font-size: 40px;
    color: black;
}