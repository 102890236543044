
.harddiskdrives {
    background-color: rgba( 100,100,100, .5);
    padding: 15px;
    color: white;
    border-radius: 15px;
}

.harddiskdrives .head {
    font-weight: bold;
    font-size: 15px;
    padding: 5px;
    background-color: rgba( 255, 100, 100, .5);
}

.harddiskdrives .data {
    margin-top: 10px;
}

.harddiskdrives .data:hover {
    background-color: rgba(255,255,255, 0.4);
}

.harddiskdrives .ok {
    color: green;
}
.harddiskdrives .warning {
    color: yellow;
}
.harddiskdrives .critical {
    color: orange;
}
.harddiskdrives .fatal {
    color: red;
}