
.keyValuePage {
    background-color: rgba( 200,200,200, .3 );
    padding: 15px;
    border-radius: 15px;
    color: black;
}

.keyValuePage .head {
    font-size: 20px;
    font-weight: bold;
}

.keyValuePage a {
    color: darkred;
}